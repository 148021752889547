import React from 'react'
import { ArrowProps } from 'react-multi-carousel'
import ArrowLeft from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'

function LeftArrow ({ onClick }: ArrowProps) {
  return (
    <button onClick={onClick} type='button' className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left'>
      <ArrowLeft width={40} height={40} color='orange--base' />
    </button>
  )
}

export default LeftArrow
