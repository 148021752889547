// @flow
import React, { useState, useLayoutEffect, useRef, ReactNode } from 'react'
import Carousel from 'react-multi-carousel'

// Components
import RightArrow from '../Arrows/RightArrow'
import LeftArrow from '../Arrows/LeftArrow'

// Hooks
import useWidth from '../../../hooks/useWidth'

import { Container } from './style'

type DefaultCarouselProps = {
  children: ReactNode[];
  sm?: {
    items?: number;
    slidesToSlide?: number;
    partialVisibilityGutter?: number;
  };
  md?: {
    items?: number;
    slidesToSlide?: number;
    partialVisibilityGutter?: number;
  };
  lg?: {
    items?: number;
    slidesToSlide?: number;
    partialVisibilityGutter?: number;
  };
  xl?: {
    items?: number;
    slidesToSlide?: number;
    partialVisibilityGutter?: number;
  };
  removeArrowOnDeviceType?: string[];
  containerClass?: string;
  beforeChange?: Function;
}

const WIDTH_MD = 768
const WIDTH_LG = 992
const WIDTH_XL = 1200

function DefaultCarousel ({ children, sm, md, lg, xl, removeArrowOnDeviceType, containerClass, beforeChange }: DefaultCarouselProps) {
  const CarouselRef = useRef()
  const windowWidth = useWidth(300)
  const [ breakpoint, setBreakpoint ] = useState(sm)
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: xl.items,
      slidesToSlide: xl.slidesToSlide,
      partialVisibilityGutter: xl.partialVisibilityGutter,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: lg.items,
      slidesToSlide: lg.slidesToSlide,
      partialVisibilityGutter: lg.partialVisibilityGutter,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: md.items,
      slidesToSlide: md.slidesToSlide,
      partialVisibilityGutter: md.partialVisibilityGutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: sm.items,
      slidesToSlide: sm.slidesToSlide,
      partialVisibilityGutter: sm.partialVisibilityGutter,
    },
  }

  useLayoutEffect(() => {
    if (windowWidth >= WIDTH_XL) {
      setBreakpoint(xl)
    } else if (windowWidth >= WIDTH_LG && windowWidth < WIDTH_XL) {
      setBreakpoint(lg)
    } else if (windowWidth >= WIDTH_MD && windowWidth < WIDTH_LG) {
      setBreakpoint(md)
    } else {
      setBreakpoint(sm)
    }

    CarouselRef?.current.goToSlide(0)
  }, [ windowWidth ])

  return (
    <Container>
      <Carousel
        ref={CarouselRef}
        responsive={responsive}
        customRightArrow={<RightArrow />}
        customLeftArrow={<LeftArrow />}
        showDots={children.length > breakpoint.items}
        partialVisible={true}
        removeArrowOnDeviceType={removeArrowOnDeviceType}
        containerClass={containerClass}
        beforeChange={beforeChange}
      >
        {children}
      </Carousel>
    </Container>
  )
}

DefaultCarousel.defaultProps = {
  sm: {
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  md: {
    items: 2,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  lg: {
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  xl: {
    items: 4,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
  centerMode: false,
  infinite: false,
  focusOnSelect: false,
  draggable: true,
}

export default DefaultCarousel
