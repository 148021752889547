import styled from 'styled-components'
import { orange } from '../../../styles/colors'

export const Container = styled.div`

  .react-multi-carousel-track {
    padding: 30px 0 50px;
  }

  /* Arrows */
  .react-multiple-carousel__arrow {
    bottom: -8px;
    outline: none;
    z-index: 1;

    &::before {
      content: none;
    }
  }

  /* Dots */
  .react-multi-carousel-dot-list {

    .react-multi-carousel-dot {

      button {
        background-color: ${orange.base};
        border: none;
        opacity: 0.5;
        width: 8px;
        height: 8px;
      }

      &--active {
        button {
          opacity: 1;
        }
      }
    }
  }
`
