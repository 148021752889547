import React from 'react'
import { ArrowProps } from 'react-multi-carousel'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'

function RightArrow ({ onClick }: ArrowProps) {
  return (
    <button onClick={onClick} type='button' className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'>
      <ArrowRight width={40} height={40} color='orange--base' />
    </button>
  )
}

export default RightArrow
